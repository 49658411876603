/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Box } from '@mui/material';

const DonutChart = ({ overviewData }) => {
  const [chartState, setChartState] = useState({
    series: [overviewData?.dotsHours, overviewData?.diloHours, overviewData?.rasHours],
    options: {
      labels: [
        `Activity Study Hours: ${get(overviewData, 'dotsHours', 0).toFixed(2)}`,
        `Role Study Hours: ${get(overviewData, 'diloHours', 0).toFixed(2)}`,
        `Efficiency Study Hours: ${get(overviewData, 'rasHours', 0).toFixed(2)}`,
      ],
      colors: ['#0ba0ef', '#784fc2', '#f59d0f'],
      plotOptions: {
        legend: {
          horizontalAlign: 'right',
          fontSize: '26px',
          onItemClick: {
            toggleDataSeries: false,
            expandOnClick: false,
          },
        },
        pie: {
          donut: {
            size: '35%',
          },
        },
      },
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName.split(':')[0],
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              // width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        position: 'right',
        offsetY: 0,
        height: 230,
      },
    },
  });
  return overviewData ? (
    <Box width={900}>
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart options={chartState.options} series={chartState.series} type="donut" width={480} />
        </div>
      </div>

      {/* <div className="actions">
        <button onClick={appendData}>+ ADD</button>
        &nbsp;
        <button onClick={removeData}>- REMOVE</button>
        &nbsp;
        <button onClick={randomize}>RANDOMIZE</button>
        &nbsp;
        <button onClick={reset}>RESET</button>
      </div> */}
    </Box>
  ) : null;
};

export default DonutChart;
DonutChart.propTypes = {
  overviewData: PropTypes.any,
};
