import { Alert, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// eslint-disable-next-line import/no-cycle
import DataTable from '../../../components/table';

const FieldUserNestedPage = ({ row, nestedRowProps }) => {
  const headCells = [
    {
      id: 'customerName',
      numeric: false,
      // disablePadding: true,
      label: 'Customer Name',
      titleCase: true,
      // align: 'left',
    },
    {
      id: 'projectName',
      numeric: false,
      // disablePadding: true,
      label: 'Project Name',
      titleCase: true,
      // align: 'left',
    },

    {
      id: 'averageHours',
      disablePadding: false,
      label: 'Average Hrs ',
    },
    {
      id: 'studyDays',
      disablePadding: false,
      label: 'Study Days',
    },
    {
      id: 'totalHours',
      disablePadding: false,
      label: 'Total Hrs ',
    },
    {
      id: 'totalStudies',
      disablePadding: false,
      label: 'Total Studies ',
    },
    {
      id: 'dotsHours',
      disablePadding: false,
      label: 'Activity Hrs',
    },
    {
      id: 'rasHours',
      disablePadding: false,
      label: 'Efficiency Hrs',
    },
    {
      id: 'diloHours',
      disablePadding: false,
      label: 'Role Hrs',
    },
    {
      id: 'observations',
      disablePadding: false,
      label: 'Observations',
    },
  ];
  const userRows = useMemo(() => {
    if (row?.statSettings && row?.statSettings?.length > 0) {
      return row?.statSettings?.map((user) => ({
        ...user,
        _id: user?.userID,
      }));
    }
    return [];
  }, [row?.statSettings]);
  if (!row?.statSettings || row?.statSettings?.length <= 0) {
    return (
      <TableRow>
        <TableCell sx={{ py: 2 }} align="center" colSpan={12}>
          <Typography variant="body2" sx={{ color: '#84909C' }}>
            No Stats Available
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell sx={{ py: 2 }} colSpan={12}>
        <Typography sx={{ mb: 2, mx: 1 }} variant="h6">
          User Report
        </Typography>
        <DataTable
          // isLoading={isLoading}
          headCells={headCells}
          rows={userRows}
          // handleSelectionClick={handleSelectionClick}
          filterKeys={['fullName']}
          enableDelete
          disableCheckbox
          noToolbar
          maxHeight={453}
          disableActions
          hideCompactViewButton
          // toolbarProps={toolbarProps}
          defaultRowsPerPage={5}
        />
        {/* <Stack direction="column" gap>
          {row?.changes?.map(
            (rowMessage) =>
              rowMessage && (
                <Alert icon={false} color="info" variant="standard">
                  {rowMessage}
                </Alert>
              )
          )}
        </Stack> */}
      </TableCell>
    </TableRow>
  );
};

FieldUserNestedPage.propTypes = {
  row: PropTypes.any.isRequired,
  nestedRowProps: PropTypes.object,
};

export default FieldUserNestedPage;
