/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import Iconify from '../../components/Iconify';
import CustomSelect from '../../components/select';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import { filterEmptyValues } from '../../utils';
import { addObservationSchema } from '../../utils/schema';
import { addInvalidObservation, updateObservation } from './studiesSlice';

const SingleStudyNestedRow = ({
  row,
  closeNestedRow,
  setNestedCollapsedRows,
  handleOpenBackdrop,
  handleCloseBackdrop,
  nestedRowProps,
  headCells,
  rows,
}) => {
  const { updateStudyLoading, updateObservationLoading } = useSelector((state) => state.studies);
  const { roles } = useSelector((state) => state?.roles);
  const { areas } = useSelector((state) => state.areas);
  const { elements } = useSelector((state) => state.projectElements);
  const { projectCategories } = useSelector((state) => state.projectCategories);
  const ratingOptions = useMemo(() => {
    const array = [];
    for (let i = 60; i <= 120; i += 5) {
      array.push({ label: i.toString(), value: i.toString() });
    }
    array.push({ label: 'Not Rated', value: 'Not Rated' });
    return array;
  }, []);
  const { singleProjectData } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const Role = jwtToken && jwtDecode(jwtToken);
  const role = String(Role?.role) || '0';
  const { tasks, isLoading: isTaskLoading } = useSelector((state) => state.tasks);
  const form = useForm({
    defaultValues: {
      areaID: get(row, 'areaID', ''),
      elementID: get(row, 'elementID', ''),
      taskID: get(row, 'taskID', ''),
      roleID: get(row, 'roleID', ''),
      rating: get(row, 'rating', null),
      frequency: get(row, 'frequency', null),
      notes: get(row, 'notes', ''),
      duration: get(row, 'duration', '') || 0,
    },
    resolver: yupResolver(addObservationSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const task = watch('taskID');
  useEffect(() => {
    if (nestedRowProps?.setCurrentTask) {
      nestedRowProps?.setCurrentTask(task);
    }
  }, [nestedRowProps, task]);

  const {
    taskSelectOptions = [],
    rolesSelectOptions = [],
    areasOptions = [],
    elementSelectOptions = [],
    setisModalopen,
  } = nestedRowProps;
  const filteredRolesOptions = useMemo(() => {
    if (row?.studyType === 1 && (singleProjectData?.multipleCountsPerArea || singleProjectData?.multipleTrackingPerArea)) {
      return roles
        ?.filter((currentRole) => {
          if (
            singleProjectData.multipleCountsPerArea &&
            currentRole.uniqueName === 'customer_count' &&
            projectCategories.some((cat) => cat.uniqueName === 'customer_count' && cat?.projectSetting?.status === 'active')
          ) {
            return true;
          }
          if (
            singleProjectData.multipleTrackingPerArea &&
            currentRole.uniqueName === 'tracking' &&
            projectCategories.some((cat) => cat.uniqueName === 'tracking' && cat?.projectSetting?.status === 'active')
          ) {
            return true;
          }
          if (
            currentRole?.projectSetting?.studyTypes?.includes(row.studyType) &&
            currentRole.uniqueName !== 'customer_count' &&
            currentRole.uniqueName !== 'tracking'
          ) {
            return true;
          }
          return false;
        })
        .map((currentRole) => ({ label: currentRole?.name, value: currentRole?._id }));
    }
    if (!rolesSelectOptions?.length > 0) {
      return [];
    }
    return rolesSelectOptions?.filter((roleOption) => {
      const roleId = roleOption?.value;
      const isRoleExistInRound = rows?.some(
        (curRow) => curRow?._id !== row?._id && curRow?.roleID === roleId && curRow?.roundID === row?.roundID
      );
      return !isRoleExistInRound;
    });
  }, [
    projectCategories,
    roles,
    rolesSelectOptions,
    row?._id,
    row?.roundID,
    row.studyType,
    rows,
    singleProjectData.multipleCountsPerArea,
    singleProjectData.multipleTrackingPerArea,
  ]);
  const roleValue = watch('roleID');
  const areaValue = watch('areaID');
  const commonRoundRows = rows?.filter((curRow) => curRow?._id !== row?._id && curRow?.roundID === row?.roundID);
  const filteredElementOptions = useMemo(() => {
    if (
      row?.studyType === 1 &&
      (singleProjectData?.multipleCountsPerArea || singleProjectData?.multipleTrackingPerArea) &&
      (roleValue === '5ad4788c6d849d5a1465c916' || roleValue === '5c6292a637a0ce70533d36a3')
    ) {
      return elements
        ?.filter((currentElement) => {
          if (currentElement?.projectSetting?.status !== 'active') {
            return false;
          }
          if (
            roleValue === '5ad4788c6d849d5a1465c916' &&
            currentElement?.projectSetting?.categoryID === '5a9e9dcacba8ec63ac9ebf8a'
          ) {
            return true;
          }
          if (
            roleValue === '5c6292a637a0ce70533d36a3' &&
            currentElement?.projectSetting?.categoryID === '5c52f83ece22ab39727c59d5'
          ) {
            return true;
          }
          return false;
        })
        .map((currentElement) => ({ label: currentElement?.name, value: currentElement?._id }));
    }
    return elementSelectOptions;
  }, [
    elementSelectOptions,
    elements,
    roleValue,
    row?.studyType,
    singleProjectData?.multipleCountsPerArea,
    singleProjectData?.multipleTrackingPerArea,
  ]);

  const filteredAreaOptions = useMemo(() => {
    if (
      row?.studyType === 1 &&
      (singleProjectData?.multipleCountsPerArea || singleProjectData?.multipleTrackingPerArea) &&
      (roleValue === '5ad4788c6d849d5a1465c916' || roleValue === '5c6292a637a0ce70533d36a3')
    ) {
      return areas
        ?.filter((currentArea) => {
          if (currentArea?.projectSetting?.status !== 'active') {
            return false;
          }
          if (
            filteredElementOptions.every((element) =>
              commonRoundRows.some((curRow) => curRow?.areaID === currentArea?._id && curRow?.elementID === element?.value)
            )
          ) {
            return false;
          }
          if (roleValue === '5ad4788c6d849d5a1465c916' && currentArea?.projectSetting?.inCustomerCount) {
            // case if role is customer count
            return true;
          }
          if (roleValue === '5c6292a637a0ce70533d36a3' && currentArea?.projectSetting?.inTracking) {
            return true;
          }
          return false;
        })
        .map((currentArea) => ({ label: currentArea?.name, value: currentArea?._id }));
    }
    return areasOptions;
  }, [
    areas,
    areasOptions,
    commonRoundRows,
    filteredElementOptions,
    roleValue,
    row?.studyType,
    singleProjectData?.multipleCountsPerArea,
    singleProjectData?.multipleTrackingPerArea,
  ]);
  const currentTask = watch('taskID');
  const currentTaskElements = useMemo(() => {
    if (!currentTask || !tasks?.length || !elementSelectOptions?.length) {
      return [];
    }
    const currentTaskObject = tasks?.find((el) => el?._id === currentTask);
    let filteredElements = [];
    if (currentTaskObject) {
      filteredElements = currentTaskObject?.projectSetting?.elements.map((newElement) =>
        elementSelectOptions.find((el) => newElement === el?.value)
      );
    }
    return filteredElements.filter((element) => {
      return element !== '' && element !== null && element !== undefined;
    });
  }, [currentTask, elementSelectOptions, tasks]);

  const { id: projectID } = useParams();
  const onSubmit = (values) => {
    const payload = { ...values };
    const areaName = values?.areaID ? areasOptions?.find((option) => option?.value === values?.areaID)?.label : '';
    const elementName = values?.elementID
      ? elementSelectOptions?.find((option) => option?.value === values?.elementID)?.label
      : '';
    const categoryName = values?.elementID
      ? elementSelectOptions?.find((option) => option?.value === values?.elementID)?.categoryName
      : '';
    const categoryID = values?.elementID
      ? elementSelectOptions?.find((option) => option?.value === values?.elementID)?.categoryID
      : '';
    if (values?.elementID) {
      payload.categoryName = categoryName;
      payload.categoryID = categoryID;
    }
    if (row?.studyType && row?.studyType === 1 && row?.roundID) {
      const roleName = values?.roleID ? rolesSelectOptions?.find((option) => option?.value === values?.roleID)?.label : '';
      payload.roundID = row?.roundID;
      payload.duration = row?.duration;
      payload.roundDuration = row?.roundDuration;
      payload.areaName = areaName || '';
      payload.roleName = roleName || '';
      payload.elementName = elementName || '';
    } else {
      const taskName = values?.taskID ? taskSelectOptions?.find((option) => option?.value === values?.taskID)?.label : '';
      payload.taskName = taskName || '';
      payload.areaName = areaName || '';
      payload.elementName = elementName || '';
    }
    const filteredPayload = filterEmptyValues(payload);
    if (values?.notes === '') {
      filteredPayload.notes = '';
    }
    if (row?.studyType && row?.studyType === 1 && (values?.duration === 0 || row?.duration === 0)) {
      filteredPayload.duration = 0;
    }
    if (row?.studyType && row?.studyType === 1 && (values?.roundDuration === 0 || row?.roundDuration === 0)) {
      filteredPayload.roundDuration = 0;
    }
    if (nestedRowProps?.nestedType === 'invalidStudy' && get(row, '_id')) {
      filteredPayload.startTime = row?.startTime;
      filteredPayload.endTime = row?.endTime;
      dispatch(
        addInvalidObservation({
          _id: get(row, '_id'),
          studyType: get(row, 'studyType'),
          studyID: get(row, 'studyID'),
          projectID,
          invalidObsIndex: get(row, 'invalidObsIndex'),
          ...filteredPayload,
        })
      )
        .then((response) => {
          if (response.payload?.success) {
            setNestedCollapsedRows([]);
            // nestedRowProps?.fetchStudies();
            if (nestedRowProps?.nestedType === 'invalidStudy') {
              nestedRowProps?.fetchStudy();
            } else {
              // nestedRowProps?.fetchDataSummaries();
            }
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Study Updated Successfully'),
              })
            );
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    } else if (get(row, '_id')) {
      dispatch(
        updateObservation({
          _id: get(row, '_id'),
          studyType: get(row, 'studyType'),
          studyID: get(row, 'studyID'),
          projectID,
          ...filteredPayload,
        })
      )
        .then((response) => {
          if (response.payload?.success) {
            setNestedCollapsedRows([]);
            // nestedRowProps?.fetchStudies();
            if (nestedRowProps?.nestedType === 'singleStudy') {
              // nestedRowProps?.fetchStudy();
            } else {
              // nestedRowProps?.fetchDataSummaries();
            }
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Study Updated Successfully'),
              })
            );
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const frequencyInputFields = register('frequency');
  const frequencyValue = watch('frequency');
  const handleCreateElementCLicked = () => {
    if (nestedRowProps?.openModal) {
      nestedRowProps?.openModal();
    } else if (setisModalopen) {
      setisModalopen(true);
    }
  };

  if (row?._id) {
    return (
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        {/* <TableCell padding="checkbox" /> */}
        {nestedRowProps?.nestedType === 'invalidStudy' ? null : <TableCell padding="checkbox" />}
        {/* <TableCell colSpan={nestedRowProps?.nestedType === 'singleStudy' ? 12 : 13} sx={{ verticalAlign: 'top' }}> */}
        <TableCell colSpan={headCells?.length ? headCells.length + 1 : 9} sx={{ verticalAlign: 'top' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DevTool control={control} />
            <Stack gap={4} direction="row">
              <Stack gap={2} width="70%" direction="row" justifyContent="space-between">
                <Grid container columnSpacing={8} px={8}>
                  {row?.studyType !== 1 ? (
                    <Grid item xs={12} md={4}>
                      <label style={{ fontSize: '0.8600rem' }}>Task</label>
                      <CustomSelect
                        name="taskID"
                        label="Select Task"
                        control={control}
                        options={taskSelectOptions}
                        error={!!errors?.taskID}
                        helperText={errors?.taskID && errors?.taskID?.message}
                        isLoading
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={4}>
                      <label style={{ fontSize: '0.8600rem' }}>Role</label>
                      <CustomSelect
                        name="roleID"
                        label="Select Role"
                        control={control}
                        options={filteredRolesOptions}
                        error={!!errors?.roleID}
                        helperText={errors?.roleID && errors?.roleID?.message}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <label style={{ fontSize: '0.8600rem' }}>Area</label>
                    <CustomSelect
                      name="areaID"
                      label="Select Area"
                      control={control}
                      options={filteredAreaOptions}
                      error={!!errors?.areaID}
                      helperText={errors?.areaID && errors?.areaID?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label style={{ fontSize: '0.8600rem' }}>Rating</label>
                    <CustomSelect
                      name="rating"
                      label="Select Rating"
                      control={control}
                      options={ratingOptions}
                      error={!!errors?.rating}
                      helperText={errors?.rating && errors?.rating?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack width="100%" direction="column">
                      <Stack alignItems="center" direction="row">
                        <label style={{ fontSize: '0.8600rem' }}>Element</label>
                        {role !== '3' && (
                          <ThemeButton size="small" onClick={handleCreateElementCLicked}>
                            Create Element
                          </ThemeButton>
                        )}
                      </Stack>
                      <CustomSelect
                        name="elementID"
                        label="Select Element"
                        control={control}
                        isDisabled={(currentElement) => {
                          if (
                            row?.studyType !== 1 ||
                            (roleValue !== '5ad4788c6d849d5a1465c916' && roleValue !== '5c6292a637a0ce70533d36a3')
                          ) {
                            return false;
                          }
                          if (
                            commonRoundRows.some(
                              (currRow) => currRow.areaID === areaValue && currentElement?.value === currRow.elementID
                            )
                          ) {
                            return true;
                          }
                          return false;
                        }}
                        options={row?.studyType !== 1 ? currentTaskElements : filteredElementOptions}
                        error={!!errors?.elementID}
                        helperText={errors?.elementID && errors?.elementID?.message}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4} mt={0.6}>
                    <FormInput
                      label="Notes"
                      placeholder="Write your notes"
                      InputProps={{ ...register('notes') }}
                      error={!!errors?.notes}
                      helperText={errors?.notes && errors?.notes?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} mt={0.6}>
                    <FormInput
                      label="Frequency"
                      type="number"
                      placeholder="Write frequency"
                      InputProps={{
                        name: frequencyInputFields.name,
                        onblur: frequencyInputFields.onBlur,
                        ref: frequencyInputFields.ref,
                        value: frequencyValue,
                        onChange: (event) => {
                          const newValue = event.target.value;
                          if (newValue === '' || Number(newValue) >= 0) {
                            return frequencyInputFields.onChange(event);
                          }
                          return null;
                        },
                      }}
                      error={!!errors?.frequency}
                      helperText={errors?.frequency && errors?.frequency?.message}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack alignItems="center" justifyContent="center" direction="row">
                <BootstrapTooltip enterDelay={500} title="Save">
                  {updateStudyLoading ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <IconButton type="submit" sx={{ p: 0.5, color: 'primary.main' }}>
                      {updateObservationLoading ? <CircularProgress size={16} /> : <Iconify icon="tabler:check" />}
                    </IconButton>
                  )}
                </BootstrapTooltip>
                <BootstrapTooltip enterDelay={500} title="Cancel">
                  <IconButton
                    disabled={updateObservationLoading}
                    onClick={() => closeNestedRow(row)}
                    sx={{ p: 0.5, color: 'error.main' }}
                  >
                    <Iconify icon="tabler:x" />
                  </IconButton>
                </BootstrapTooltip>
              </Stack>
            </Stack>
          </form>
        </TableCell>
        {/* <TableCell>
          <Stack alignItems="center" justifyContent="center" direction="row">
            <BootstrapTooltip enterDelay={500} title="Save">
              <IconButton onClick={handleSubmit} sx={{ p: 0.5, color: 'primary.main' }}>
                <Iconify icon="tabler:check" />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip enterDelay={500} title="Cancel">
              <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                <Iconify icon="tabler:x" />
              </IconButton>
            </BootstrapTooltip>
          </Stack>
        </TableCell> */}
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell colSpan={12}>
        <Typography sx={{ ml: 5, color: '#4444448f' }} variant="subtitle2">
          No Elements found
        </Typography>
      </TableCell>
    </TableRow>
  );
};

SingleStudyNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  closeNestedRow: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  setNestedCollapsedRows: PropTypes.func,
  nestedRowProps: PropTypes.object,
  headCells: PropTypes.object,
  rows: PropTypes.object,
};

export default SingleStudyNestedRow;
