import { Icon } from '@iconify/react/dist/iconify';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import {
  Box,
  Card,
  Container,
  DialogActions,
  Drawer,
  Grid,
  LinearProgress,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import { get, has } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as NavigateLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ModalComponent from '../../components/ModalComponent';
import { ThemeButton } from '../../components/button';
import { ReusableTabs } from '../../components/reusableTabs';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { toggleDownload } from '../../layout/dashboard/dashboardSlice';
import { APP_ROUTER_BASE_URL } from '../../utils';
import GroupRulesPage from '../groupRole';
import NotesPage from '../notes/notesPage';
import ProjectLogsPage from '../projectLogs/projectLogsPage';
import QuestionPage from '../question';
import RemindersPage from '../reminder/RemindersPage';
import StudyPage from '../studiesPage';
import StudyLogsPage from '../studieslogs/studyLogsPage';
import TasksListPage from '../tasksPage/TasksListPage';
import DownloadComponent from './DownloadComponent';
import OverViewPage from './OverViewPage';
import AreasPage from './areas/AreasPage';
import CategoriesPage from './category/CategoriesPage';
import DataSummaryPage from './dataSummary/DataSummaryPage';
import ElementsPage from './elements/ElementsPage';
import FieldUser from './fieldUser/FieldUser';
import LocationsPage from './location/LocationsPage';
import {
  downloadCoverageReport,
  downloadCrib,
  downloadProject,
  getImportProjectProgress,
  getSingleProject,
  importProject,
  resetImport,
} from './projectSlice';
import RolesPage from './roles/RolesPage';
import axiosInstance from '../../utils/axiosInstance';

const SingleProjectPage = () => {
  const {
    singleProjectData,
    isSingleProjectLoading,
    isProjectDownloading,
    isCoverageDownloading,
    isCribDownloading,
    progressStatus,
    importMessage,
    importProgress,
  } = useSelector((state) => state.project);
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const decoded = jwtToken && jwtDecode(jwtToken);
  const role = decoded?.role || '0';
  const location = useLocation();
  const locationState = location.state;
  const { isDownloadOpen } = useSelector((state) => state.dashboard);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalopen, setisModalopen] = useState(false);
  const [isProgressModalOpen, setProgressModalOpen] = useState(false);

  const fieldUserTabs = [
    { label: 'Studies', value: 'studies' },
    { label: 'Data Summary', value: 'dataSummary' },
    { label: 'Notes', value: 'notes' },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (locationState?.isNewProject) {
      setisModalopen(true);
      // navigate(location.pathname, { replace: true });
    }
  }, [location.pathname, locationState?.isNewProject, navigate]);
  useEffect(() => {
    if (id) {
      dispatch(getSingleProject(id));
    }
  }, [dispatch, id]);
  const [selectedTab, setSelectedTab] = useState('studies');
  const onTabChange = (event, newValue) => setSelectedTab(newValue);
  const navigateToEditPage = () => {
    if (id) {
      navigate(`${APP_ROUTER_BASE_URL}project/edit/${id}`);
    }
  };
  const renderTabContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <OverViewPage projectID={singleProjectData?._id} />;
      case 'fieldUsers':
        return (
          <FieldUser
            projectID={singleProjectData?._id}
            customerID={singleProjectData?.customerID}
            customerName={singleProjectData?.customerName}
            project={singleProjectData}
          />
        );
      case 'tasks':
        return <TasksListPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'studies':
        return <StudyPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'groupRules':
        return <GroupRulesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'question':
        return <QuestionPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'reminders':
        return <RemindersPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'categories':
        return <CategoriesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'areas':
        return <AreasPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'roles':
        return <RolesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'elements':
        return <ElementsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'dataSummary':
        return (
          <DataSummaryPage
            singleProjectData={singleProjectData}
            customerID={singleProjectData?.customerID}
            projectID={singleProjectData?._id}
          />
        );
      case 'notes':
        return <NotesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'logs':
        return <ProjectLogsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'studyLogs':
        return <StudyLogsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'location':
        return <LocationsPage projectID={singleProjectData?._id} customerID={singleProjectData?.customerID} />;
      default:
        return null;
    }
  };
  const toggleDrawer = () => dispatch(toggleDownload());
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    if (locationState?.isNewProject) {
      navigate(location.pathname, { replace: true });
    }
    // clear locationState here
  };
  const openModal = () => {
    setisModalopen(true);
  };
  const handleDownloadStats = async () => {
    const payload = {
      projectID: get(singleProjectData, '_id'),
      customerID: get(singleProjectData, 'customerID'),
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };
    dispatch(downloadProject(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Click download links to see files'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const handleDownloadCrib = async () => {
    const payload = {
      projectID: get(singleProjectData, '_id'),
      customerID: get(singleProjectData, 'customerID'),
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };
    dispatch(downloadCrib(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Click download links to see files'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const handleCoverageReport = async () => {
    const payload = {
      projectID: get(singleProjectData, '_id'),
      customerID: get(singleProjectData, 'customerID'),
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };
    dispatch(downloadCoverageReport(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Click download links to see files'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const downloadProjectTemplate = () => {
    // File path is relative to the public folder
    const filePath = '/retime-import-project-template.xlsx'; // Make sure the path is correct

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = filePath; // Set the href to the file path
    link.download = 'retime-import-project-template.xlsx'; // Default file name when downloading
    document.body.appendChild(link); // Append the link to the document body
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up by removing the link after download
  };
  const importProjectTemplate = () => {
    document.getElementById('fileInput').click();
  };
  useEffect(() => {
    if (progressStatus === 'ERROR') {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: importMessage,
        })
      );
      closeProgressModal();
      dispatch(resetImport());
    }
    if (progressStatus === 'COMPLETED') {
      // Delay the reset action by 3 seconds
      const timeoutId = setTimeout(() => {
        closeProgressModal();
        dispatch(resetImport());
        dispatch(getSingleProject(id));
      }, 3000);

      // Cleanup the timeout when component unmounts or progressStatus changes
      return () => {
        clearTimeout(timeoutId);
      };
    }
    if (progressStatus === 'PENDING' && !isProgressModalOpen) {
      setProgressModalOpen(() => true);
    }
    return undefined;
  }, [dispatch, id, importMessage, isProgressModalOpen, progressStatus]);
  const isOnlyEfficiency = useMemo(() => {
    if (!singleProjectData || !singleProjectData?.studyTypes) {
      return false;
    }
    if (singleProjectData?.studyTypes?.length === 1 && singleProjectData?.studyTypes?.includes(1)) {
      return true;
    }
    return false;
  }, [singleProjectData]);
  const filteredTabs = useMemo(() => {
    if (isOnlyEfficiency) {
      return [
        { label: 'Overview', value: 'overview' },
        { label: 'Locations', value: 'location' },
        { label: 'Field Users', value: 'fieldUsers' },
        { label: 'Categories', value: 'categories' },
        { label: 'Areas', value: 'areas' },
        { label: 'Roles', value: 'roles' },
        { label: 'Elements', value: 'elements' },
        { label: 'Studies', value: 'studies' },
        { label: 'Data Summary', value: 'dataSummary' },
        { label: 'Notes', value: 'notes' },
        { label: 'Groups', value: 'groupRules' },
        { label: 'Questions', value: 'question' },
        { label: 'Reminders', value: 'reminders' },
        { label: 'Audit', value: 'logs' },
        { label: 'Study Audit', value: 'studyLogs' },
      ];
    }
    return [
      { label: 'Overview', value: 'overview' },
      { label: 'Locations', value: 'location' },
      { label: 'Field Users', value: 'fieldUsers' },
      { label: 'Categories', value: 'categories' },
      { label: 'Areas', value: 'areas' },
      { label: 'Roles', value: 'roles' },
      { label: 'Elements', value: 'elements' },
      { label: 'Tasks', value: 'tasks' },
      { label: 'Studies', value: 'studies' },
      { label: 'Data Summary', value: 'dataSummary' },
      { label: 'Notes', value: 'notes' },
      { label: 'Groups', value: 'groupRules' },
      { label: 'Questions', value: 'question' },
      { label: 'Reminders', value: 'reminders' },
      { label: 'Audit', value: 'logs' },
      { label: 'Study Audit', value: 'studyLogs' },
    ];
  }, [isOnlyEfficiency]);

  const fetchProgress = async (importId) => {
    if (importId) {
      // Initial dispatch to fetch progress
      await dispatch(getImportProjectProgress(importId));

      const intervalId = setInterval(async () => {
        await dispatch(getImportProjectProgress(importId)).then((res) => {
          // Check the status from the response
          if (res?.payload?.data?.status !== 'PENDING') {
            clearInterval(intervalId); // Stop polling if status is not 'PENDING'
          }
        });
      }, 10000);
    }
  };
  const handleFileChange = async (event) => {
    const selectedFile = await event.target.files[0];
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }
    const formData = new FormData();
    formData.append('projectID', singleProjectData?._id);
    formData.append('customerID', singleProjectData?.customerID);
    formData.append('customerName', singleProjectData?.customerName);
    formData.append('projectName', singleProjectData?.name);
    formData.append('file', selectedFile);
    try {
      // Dispatch with the FormData
      // const response = await dispatch(importProject(formData));
      const response = await axiosInstance({
        url: 'projects/import',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': undefined, // Let axios handle the Content-Type automatically
        },
      });
      if (get(response, 'data.success')) {
        // closeModal();
        setisModalopen((prevState) => !prevState);
        setProgressModalOpen(true);
        fetchProgress(get(response, 'data.data._id'));
      }
    } catch (error) {
      console.error('Error during importProject:', error);
    }
  };
  const closeProgressModal = () => {
    setProgressModalOpen(false);
  };
  const getFormatedDate = (date) => {
    const formatedDate = dayjs(date).format('MMMM D, YYYY');
    return formatedDate;
  };
  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 'fit-content !important',
            maxHeight: '500px',
          },
        }}
        title="Import Project"
        open={isProgressModalOpen}
        // onClose={closeProgressModal}
      >
        <Stack width={500} direction="column" justifyContent="center" gap={2} my={1}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="buffer" value={importProgress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {`${Math.round(importProgress)}%`}
              </Typography>
            </Box>
          </Box>
          <Stack direction="row" justifyContent="center">
            <Typography variant="body2">{importMessage}</Typography>
          </Stack>
        </Stack>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 'fit-content !important',
            maxHeight: '700px',
          },
        }}
        title="Import Project"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ my: 2, px: 1, mx: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
          </DialogActions>
        }
      >
        <input id="fileInput" type="file" accept=".xls,.xlsx,.xlsm" style={{ display: 'none' }} onChange={handleFileChange} />
        <Stack direction="column" justifyContent="center" gap={2} my={1}>
          {/* <Typography variant="h6">Import Project</Typography> */}
          <Typography variant="bold" style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            Step 1:{' '}
            <Typography variant="body1">
              Click{' '}
              <Link onClick={downloadProjectTemplate} component="button">
                here
              </Link>{' '}
              to download the latest version of the import template.
            </Typography>
          </Typography>
          <Typography variant="bold" style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            Step 2: <Typography variant="body1">Populate the downloaded file following the instructions within.</Typography>
          </Typography>
          <Typography variant="bold" style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            Step 3:{' '}
            <Typography variant="body1">
              Once it is ready, click{' '}
              <Link onClick={importProjectTemplate} component="button">
                here
              </Link>{' '}
              to select your populated file.
            </Typography>
          </Typography>
        </Stack>
      </ModalComponent>
      <Drawer sx={{ position: 'unset' }} hideBackdrop anchor="right" open={isDownloadOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            px: 1,
            // borderRight: '1px solid #ececec',
            pl: 0,
            display: 'flex',
            width: 600,
            bgcolor: '#f1f1f1',
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <DownloadComponent />
        </Box>
      </Drawer>
      <Container maxWidth="xxl">
        <Grid sx={{ width: '100%', mb: 2 }} alignItems="center" spacing={2} container justifyContent="space-between">
          <Grid item lg={3.6} sm={12} md={6} xs={12}>
            <Stack direction="column">
              <Typography variant="h4">
                {isSingleProjectLoading ? (
                  <Skeleton width={320} />
                ) : (
                  has(singleProjectData, 'name') && get(singleProjectData, 'name')
                )}
              </Typography>
              <Grid container>
                <Grid gap={2} item sm={6} xs={6}>
                  {isSingleProjectLoading ? (
                    <Skeleton width={100} />
                  ) : (
                    <NavigateLink
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      to={`/customer/edit/${get(singleProjectData, 'customerID')}`}
                    >
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <PersonOutlineOutlinedIcon sx={{ color: '#848383' }} fontSize="small" />
                        <Link component="button">
                          <Typography variant="subtitle2">{get(singleProjectData, 'customerName')}</Typography>
                        </Link>
                        <DriveFileRenameOutlineOutlinedIcon sx={{ color: 'primary.main', cursor: 'pointer' }} fontSize="small" />
                      </Stack>
                    </NavigateLink>
                  )}
                </Grid>
                <Grid gap={2} item sm={6} xs={6}>
                  {isSingleProjectLoading ? (
                    <Skeleton width={100} />
                  ) : (
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <DateRangeOutlinedIcon sx={{ color: '#848383' }} fontSize="small" />
                      <Typography variant="subtitle2">{getFormatedDate(get(singleProjectData, 'startDate'))}</Typography>
                    </Stack>
                  )}
                </Grid>
                <Grid gap={2} item sm={6} xs={6}>
                  {isSingleProjectLoading ? (
                    <Skeleton width={100} />
                  ) : (
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <ManageAccountsOutlinedIcon sx={{ color: '#848383' }} fontSize="small" />
                      <Typography variant="subtitle2">{get(singleProjectData, 'managerName')}</Typography>
                    </Stack>
                  )}
                </Grid>
                <Grid gap={2} item sm={6} xs={6}>
                  {isSingleProjectLoading ? (
                    <Skeleton width={100} />
                  ) : (
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <EventBusyOutlinedIcon sx={{ color: '#848383' }} fontSize="small" />
                      <Typography variant="subtitle2">{getFormatedDate(get(singleProjectData, 'endDate'))}</Typography>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item lg={8.4} md={8} sm={12} xs={12}>
            <Grid justifyContent="flex-end" spacing={2} container>
              {(role === 1 || role === 2) && (
                <Grid item lg={2.4} md={4} xl={2} sm={4} xs={12}>
                  <ThemeButton
                    onClick={navigateToEditPage}
                    size="small"
                    startIcon={<Icon icon="eva:edit-fill" />}
                    fullWidth
                    variant="contained"
                  >
                    Edit Project
                  </ThemeButton>
                </Grid>
              )}
              {(role === 1 || role === 2) && (
                <Grid item lg={2.8} md={4} xl={2} sm={4} xs={12}>
                  <ThemeButton
                    size="small"
                    startIcon={<Icon width={16} icon="fa-solid:file-export" />}
                    fullWidth
                    variant="outlined"
                    onClick={openModal}
                  >
                    Import Project
                  </ThemeButton>
                </Grid>
              )}
              <Grid item lg={2} md={4} xl={2} sm={4} xs={12}>
                <ThemeButton
                  onClick={handleDownloadCrib}
                  size="small"
                  startIcon={<Icon width={16} icon="fa-solid:download" />}
                  fullWidth
                  variant="outlined"
                  loading={isCribDownloading}
                >
                  Crib
                </ThemeButton>
              </Grid>
              <Grid item lg={2} md={4} xl={2} sm={4} xs={12}>
                <ThemeButton
                  size="small"
                  startIcon={<Icon width={16} icon="fa-solid:download" />}
                  fullWidth
                  variant="outlined"
                  loading={isCoverageDownloading}
                  onClick={handleCoverageReport}
                >
                  Report
                </ThemeButton>
              </Grid>
              {(role === 1 || role === 2) && (
                <Grid item lg={2} md={4} xl={2} sm={4} xs={12}>
                  <ThemeButton
                    size="small"
                    startIcon={<Icon width={16} icon="fa-solid:download" />}
                    fullWidth
                    variant="outlined"
                    onClick={handleDownloadStats}
                    loading={isProjectDownloading}
                  >
                    Statistics
                  </ThemeButton>
                </Grid>
              )}

              <Grid item lg={2} md={4} xl={2} sm={4} xs={12}>
                <ThemeButton
                  fullWidth
                  startIcon={<FileDownloadOutlinedIcon />}
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={toggleDrawer}
                >
                  Downloads
                </ThemeButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card>
          <Card sx={{ borderBottom: '1px solid #eee' }}>
            <ReusableTabs
              tabs={role === 1 || role === 2 ? filteredTabs : fieldUserTabs}
              selectedTab={selectedTab}
              handleChange={onTabChange}
            />
          </Card>
          <Box sx={{ p: 2, bgcolor: '#f9fafb', width: '100%' }}>
            {isSingleProjectLoading ? <Skeleton variant="rectangular" width="100%" height={300} /> : renderTabContent()}
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default SingleProjectPage;
