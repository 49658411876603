import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, DialogActions, FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { ActionButton, ThemeButton } from '../../components/button';
import StatusButtons from '../../components/buttonGroup';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { Types } from '../../types';
import { filterEmptyValues } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { downloadTask, getTasks, massEditTasks } from './taskSlice';
import FormInput from '../../components/formComponents/FormInput';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import CustomSelect from '../../components/select';

const TasksToolbar = (props) => {
  const {
    selected,
    filterName,
    handleClearSelection,
    onFilterName,
    searchLable,
    toolbarProps,
    setPage,
    handleOpenBackdrop,
    handleCloseBackdrop,
    rows,
  } = props;
  const numSelected = selected?.length;
  const { onCancel, onSave, projectID } = toolbarProps;
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { singleProjectData } = useSelector((state) => state.project);
  const { isTaskDownloading, tasks } = useSelector((state) => state.tasks);
  const { id } = params;
  const onConfirmDelete = async () => {
    if (selected && selected?.length > 0) {
      handleOpenBackdrop();
      setIsModalOpen(false);
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: `tasks/delete/${selected[0]}`,
          method: 'DELETE',
          data: {
            projectID,
            taskIDs: selected,
          },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          setIsModalOpen(false);
          handleClearSelection();
          dispatch(getTasks({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        setIsModalOpen(false);
        handleClearSelection();
        handleCloseBackdrop();
      }
    }
  };
  const handleSelectionClick = async (selectedIds, status) => {
    setIsStatusModalOpen(false);
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (status === 'delete') {
      setIsModalOpen(true);
      return;
    }

    if (selectedIds && selectedIds?.length > 0) {
      const initialPayload = { taskIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'tasks/update/status',
          method: 'PATCH',
          data: initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          setIsStatusModalOpen(false);
          handleClearSelection();
          dispatch(getTasks({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('active');
  const { groups } = useSelector((state) => state.groups);
  const [isModalopen, setisModalopen] = useState(false);
  const form = useForm({
    defaultValues: {
      name: '',
      studyTypes: [],
      groupID: '',
    },
    // resolver: yupResolver(addTaskSchema),
  });
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = [
    // { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
    // { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
  ];
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const handleStatusButtonClick = (selectedIds, status) => {
    setCurrentStatus(() => status);
    setIsStatusModalOpen(() => true);
  };
  const handleDownloadTask = async () => {
    dispatch(
      downloadTask({
        projectID: singleProjectData?._id,
        customerID: singleProjectData?.customerID,
        projectName: singleProjectData?.name,
        customerName: singleProjectData?.customerName,
      })
    )
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    // setValue('tasks', []);
  };
  const onSubmit = (values) => {
    if (rows && rows?.length > 0 && selected && selected?.length > 0) {
      const payload = selected?.map((selectedId) => {
        const observation = rows?.find((row) => row?._id === selectedId) || null;
        return observation;
      });
      const finalPayload = payload?.map((observation) => {
        // name: '',
        // studyTypes: [],
        // groupID: '',
        const obsObject = {
          name: observation?.name,
          studyTypes: values?.studyTypes?.length > 0 ? values?.studyTypes : observation?.projectSetting?.studyTypes,
          groupID: values?.groupID || observation?.projectSetting?.groupID,
          controllingElementID: observation?.projectSetting?.controllingElementID,
          elements: observation?.projectSetting?.elements,
          _id: observation?._id,
          customerID: observation?.projectSetting?.customerID,
          projectID: id,
        };
        const filteredPayload = filterEmptyValues(obsObject);
        return filteredPayload;
      });
      dispatch(massEditTasks(finalPayload))
        .then((response) => {
          if (response?.payload.successCount <= 0) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          }
          closeModal();
          handleClearSelection();
          dispatch(getTasks({ projectID: id }));
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: 'Something went wrong',
            })
          );
        });
    }
  };
  const groupOptions = useMemo(
    () =>
      groups && groups?.length > 0
        ? groups
            ?.filter((currentGroup) => currentGroup?.projectSetting?.status === 'active')
            ?.map((group) => ({ label: group?.name, value: group?._id }))
        : [],
    [groups]
  );
  return (
    <>
      <ConfirmDialog
        title="Change Status"
        open={isStatusModalOpen}
        setOpen={setIsStatusModalOpen}
        selectedField={selected}
        onConfirm={() => handleSelectionClick(selected, currentStatus)}
        confirmText="Change Status"
      >
        <Typography variant="body1">
          Are you sure you want to change the status to <b>{currentStatus}</b> ?
        </Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete"
        open={isModalOpen}
        setOpen={setIsModalOpen}
        selectedField={selected}
        onConfirm={onConfirmDelete}
        confirmText="Delete"
      >
        <Typography variant="body1">
          Are you sure you want to <b>delete</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
          },
        }}
        title="Mass Edit Tasks"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, p: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} onClick={handleSubmit(onSubmit)} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              {/* <Grid item lg={12} sm={12} xs={12} md={12}>
                <FormInput
                  label="Name"
                  placeholder="Enter Name"
                  InputProps={{
                    ...register('name'),
                  }}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              </Grid> */}
              <Grid mt={1} item lg={12} sm={12} xs={12} md={12}>
                <label htmlFor="groupID" style={{ fontSize: '0.8600rem' }}>
                  Groups
                </label>
                <CustomSelect
                  id="groupID"
                  name="groupID"
                  control={control}
                  options={[{ label: 'No Group', value: '' }, ...groupOptions]}
                  error={!!errors?.groupID}
                  helperText={errors?.groupID && errors?.groupID?.message}
                  label="No Group"
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  options={studyAppOptions}
                  config={studyAppConfig}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 2,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}

        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="delete"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                size="small"
                variant="outlined"
                color="error"
                onClick={() => handleSelectionClick && handleSelectionClick(selected, 'delete')}
              >
                {`Remove ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            <ThemeButton
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="primary"
              onClick={() => setisModalopen(true)}
              startIcon={<Iconify icon="iconamoon:edit-duotone" />}
            >
              {`Mass Edit ${numSelected > 1 ? 'all' : ''}`}
            </ThemeButton>
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="activate"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                size="small"
                variant="outlined"
                onClick={() => handleSelectionClick && handleStatusButtonClick && handleStatusButtonClick(selected, 'active')}
              >
                {`Activate ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
              <ActionButton
                type="archive"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                size="small"
                variant="outlined"
                color="error"
                onClick={() => handleSelectionClick && handleStatusButtonClick && handleStatusButtonClick(selected, 'archive')}
              >
                {`Archive ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
          </Stack>
        ) : (
          <Grid p={1} container alignItems="center" justifyContent="space-between" spacing>
            <Grid item lg={5} xl={4} md={6} xs={12}>
              <Stack
                gap={2}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                <FormControl fullWidth>
                  <OutlinedInput
                    // size='small'
                    sx={{
                      fontSize: '0.86rem',
                      // mt: '5px',
                      // mb: '16px',
                      borderRadius: 25,
                      lineHeight: '1.9375em',
                      '.MuiInputBase-input': {
                        height: '0.860em',
                        py: '15px',
                      },
                    }}
                    fullWidth
                    // size='small'
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={searchLable || 'Search Tasks'}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {toolbarProps?.showReorderButton ? (
                  <>
                    {!toolbarProps?.isReording ? (
                      <ThemeButton
                        onClick={toolbarProps?.hadleReorderClick}
                        sx={{ width: { sm: '50%', xs: '40%' } }}
                        variant="outlined"
                      >
                        Re-Order Tasks
                      </ThemeButton>
                    ) : (
                      <Stack direction="row" spacing>
                        <ThemeButton sx={{ px: 3 }} onClick={onSave} variant="contained">
                          save
                        </ThemeButton>
                        <ThemeButton onClick={onCancel} variant="outlined" color="error">
                          Cancel
                        </ThemeButton>
                      </Stack>
                    )}
                  </>
                ) : null}
              </Stack>
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={5} lg={5}>
              <Stack
                gap={2}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                {tasks?.length > 0 && (
                  <ActionButton tooltip="Download Tasks" loading={isTaskDownloading} onClick={handleDownloadTask} color="primary">
                    <FileDownloadOutlinedIcon />
                  </ActionButton>
                )}
                <ThemeButton
                  onClick={toolbarProps?.openModal}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ width: { sm: '50%', xs: '50%' } }}
                  variant="outlined"
                >
                  Create Task
                </ThemeButton>
                <StatusButtons
                  status={toolbarProps?.status}
                  onStatusChange={handleStatusChange}
                  fullWidth
                  size="small"
                  // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                  sx={{ width: '90%' }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

TasksToolbar.propTypes = {
  selected: PropTypes.array,
  handleClearSelection: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  searchLable: PropTypes.string,
  setPage: PropTypes.func,
  toolbarProps: PropTypes.object,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  rows: PropTypes.array,
};

export default TasksToolbar;
